<template>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-1"></div>

      <div class="col-md-10 register-container">
        <div class="card register">
          <div class="card-body">
            <!--<span class="flex-even back" @click="goHome()">
                <i :class="['fas','fa-angle-left','mr-2']"></i>Zurück
            </span>-->
              <h2 class='card-title'>Datenschutzerklärung</h2>

                <div class='card__body'>
                  Sie befinden sich auf den Webseiten der Häfele GmbH & Co KG, www.haefele.de oder www.hafele.com. Der Schutz Ihrer Daten ist uns wichtig. Daher möchten wir Sie nachfolgend informieren, welche Daten Ihres Besuchs wir zu welchen Zwecken verwenden.<br/><br />
                  <h4 class='data__subheading'>1. Verantwortlicher<br/></h4>
                  Verantwortlicher im Sinne der Datenschutz-Grundverordnung (im Folgenden „DS-GVO") und sonstiger in den Mitgliedstaaten der Europäischen Union geltenden Bestimmungen zum Datenschutz ist die: <br/>
                  Häfele GmbH & Co KG (im Folgenden „Häfele“)<br/>
                  Adolf-Häfele-Str. 1<br/>
                  72202 Nagold<br/>
                  Tel.: + 49( 0) 74 52 95 0<br/>
                  Fax: + 49 (0) 74 52 95 2 00<br/>
                  E-Mail: <a href="mailto:info@haefele.de">info@haefele.de</a><br/>
                  <br/>
                  <br />
                  <h4 class='data__subheading'>2. Kontaktdaten des Datenschutzbeauftragten<br/></h4>
                  Der Datenschutzbeauftragte bei Häfele ist unter folgenden Kontaktdaten erreichbar:<br/>
                  Häfele GmbH & Co KG <br/>
                  Datenschutzbeauftragter <br/>
                  Adolf-Häfele-Str. 1 <br/>
                  72202 Nagold<br/>
                  E-Mail: <a href="mailto:HDEDatenschutzbeauftragter@Haefele.de">HDEDatenschutzbeauftragter@Haefele.de</a><br/>
                  <br />
                  <h4 class='data__subheading'>3. Verarbeitung von Daten<br/></h4>
                  3.1.    Allgemeines<br/>
                  Personenbezogene Daten sind alle Daten, die Sie als Person identifizierbar machen, etwa Name, Adresse, E-Mail-Adresse und Online-Kennungen. Die personenbezogenen Daten unserer Nutzer werden wie folgt verwendet: <br />
                    1.	Ausführung unserer Dienstleistungen,<br />
                    2.	Gewährleistung des technischen Supports. <br />
                  Soweit in den folgenden Abschnitten nicht anderweitig dargestellt, werden bei Nutzung unserer Webseiten grundsätzlich keine personenbezogenen Daten erhoben, verarbeitet oder genutzt.<br />
                  Als Gegenleistung für die Teilnahme an der kostenlosen virtuellen Plattform willige ich in die Nutzung meiner Kontaktdaten sowie der während des Messebesuchs per Merkliste gemerkten Produkte für eine personalisierte werbliche Ansprache per E-Mail durch die Häfele GmbH Co KG ein. Der Widerruf dieser Einwilligung ist jederzeit möglich. Mehr Details zu der Datenverarbeitung und meinen Rechten finden sich in der Datenschutzerklärung.<br />
                  <br />
                   3.2.    Speicherung von Zugriffsdaten, Erstellung von Logfiles<br />
                    Bei jedem Zugriff eines Nutzers auf eine Seite dieses Webangebots und bei jedem Abruf einer Datei werden Zugriffsdaten über diesen Vorgang in einer Protokolldatei erhoben und gespeichert. Bei den erhobenen Informationen handelt es sich um die Standard-Protokollierung. Jeder Datensatz besteht aus:<br />
                      •	Datum/Zeit der Anforderung<br />
                      •	Webseite, von der aus die Datei angefordert wurde<br />
                      •	Seiten, die vom System des Nutzers über unsere Webseite aufgerufen werden<br />
                      •	aufgerufener Dateiname<br />
                      •	übertragene Datenmenge<br />
                      •	Zugriffsstatus (Datei übertragen, Datei nicht gefunden)<br />
                      •	eine Beschreibung des verwendeten Betriebssystems und Webbrowsers, Client IP-Adresse und Benutzername (Login-Daten) von authentifizierten Benutzern<br />
                    Diese Daten dienen der Bereitstellung der Inhalte unserer Webseiten, der Gewährleistung der Funktionsfähigkeit unserer informationstechnischen Systeme und der Optimierung unseres Webangebots. Die Daten können anonymisiert für statistische Zwecke (siehe unten) sowie für Zwecke der Datensicherheit, insbesondere zur Fehleranalyse und Abwehr von Angriffsversuchen auf unseren Webserver verwendet (Art. 6 Abs. 1 f DS-GVO). Zugriffsdaten werden weder für die Erstellung von individuellen Anwenderprofilen verwendet noch an Dritte weitergegeben und werden nach spätestens 90 Tagen gelöscht.<br />
                  3.3.    Nutzung von IP-Adressen<br />
                    Bei Zugriffen auf unsere Homepage nutzt Häfele die Client IP-Adresse zur Ermittlung des Landes aus dem der Zugriff erfolgt, um den Anforderer auf die spezifische Häfele Homepage des entsprechenden Landes zu leiten. Eine weitere Verwendung erfolgt nicht, mit Ausnahme der unter Punkt 3.2. genannten Speicherung von Zugriffsdaten, Erstellung von Logfiles.<br />
                  3.4.    Kontaktformular<br />
                    Sofern auf diesen Webseiten die Möglichkeit zur Eingabe persönlicher oder geschäftlicher Daten besteht, ist die Angabe dieser Daten stets freiwillig. Angaben, die für die Durchführung der Anfrage erforderlich sind, sind mit einem ’*’ gekennzeichnet. Soweit Sie uns persönliche oder geschäftliche Daten über die Kontaktformulare mitteilen, werden wir diese nur im Rahmen der jeweiligen Zweckbestimmung nutzen. Rechtsgrundlage hierfür ist ihre Einwilligung (Art. 6 Abs. 1 lit. a DS-GVO). Zur Verhinderung unberechtigter Zugriffe Dritter auf Ihre persönlichen Daten, wird die Datenübertragung per SSL oder TLS-Technik verschlüsselt.<br />
                  3.5.    Zuliefer-Portal<br />
                    Um sich in unserem Zuliefer-Portal zu registrieren, geben Sie Name, Vorname und weitere geschäftliche Informationen an. Damit erhalten Sie Zugang zu unseren Leistungen für Zulieferer. Die Daten werden für die Dauer der Registrierung, zur Vertragserfüllung und zur Erfüllung von gesetzlichen Verpflichtungen gespeichert. Rechtsgrundlage hierfür ist Art. 6 Abs. 1 lit. a und b DS-GVO. Sie können Ihre Registrierung jederzeit widerrufen. Ihr Zugang wird dann sofort gesperrt und nach Ablauf gesetzlicher Aufbewahrungspflichten gelöscht.<br />
                  3.6.    Chat-Funktion<br />
                    Wenn Sie den Chat auf der Webseite für den Kontakt zum Kundenservice nutzen, werden bei Initialisierung des Chats verschiedene Informationen an den Kundenservice übertragen (Art. 6 Abs. 1 lit. a DS-GVO). Hierzu gehören z. B. das von Ihnen auf der Webseite ausgewählte Hilfe-Thema, Browser-Version und Betriebssystem-Version. Zusätzlich werden von der Chat-Plattform in regelmäßigen Abständen Informationen über die Erreichbarkeit des Chat-Services übertragen. Mit Hilfe dieser Information wird auf der Webseite der Button zum Starten des Chats aktiviert oder deaktiviert. Wir speichern nur die Information über den Beginn und das Ende der Kommunikation für 7 Tage. Chatinhalte werden nicht gespeichert. Zur Verhinderung unberechtigter Zugriffe Dritter auf Ihre persönlichen Daten, wird die Datenübertragung per SSL oder TLS-Technik verschlüsselt.<br />
                  3.7.    Newsletter<br />
                  Sie haben die Möglichkeit, unseren Newsletter zu abonnieren. Hierfür müssen Sie Ihre E-Mail Adresse angeben, an die wir den Newsletter verschicken können. Falls Sie dies in die Eingabemaske eingeben, erheben wir zur persönlichen Ansprache des Newsletters Ihren (Firmen-)Namen. Mit Angabe Ihrer E-Mail-Adresse willigen Sie ein, dass wir Ihre Daten für den Newsletter-Versand nutzen, um Sie so über Neuigkeiten zu informieren. Rechtsgrundlage für die Verarbeitung ist Art. 6 Abs. 1 lit. a DSGVO. Ihre E-Mail-Adresse wird zu keinem anderen Zweck verwendet. Der Versand von Newslettern erfolgt über einen Dienstleister.<br />
                  Sie haben die Möglichkeit, sich jederzeit vom Newsletter abzumelden und die von Ihnen erteilte Einwilligung für die Zukunft zu widerrufen. Hierzu klicken Sie bitte die entsprechende Schaltfläche im zugesandten Newsletter an. Ihre E-Mail-Adresse wird dann umgehend aus unserem System gelöscht.<br />
                  3.8.    Webshop<br />
                  Wenn Sie in unserem Webshop bestellen möchten, ist es für den Vertragsabschluss erforderlich, dass Sie Ihre persönlichen Daten angeben, die wir für die Abwicklung Ihrer Bestellung benötigen. Für die Abwicklung der Verträge notwendige Pflichtangaben sind gesondert – mit „*“ -  markiert, weitere Angaben sind freiwillig. Um bei uns bestellen zu können, müssen Sie Ihre unternehmensbezogenes Kundennummer eingeben. Die von Ihnen angegebenen Daten verarbeiten wir zur Abwicklung Ihrer Bestellung. Dazu können wir Ihre Zahlungsdaten an unsere Hausbank weitergeben. Rechtsgrundlage hierfür ist Art. 6 Abs. 1 S. 1 lit. b DS-GVO.<br />
                  <br />
                  Bei Zahlung per Kreditkarte, Überweisung oder Lastschrifteinzug etc. nutzten wir zur Verarbeitung Ihrer Zahlungsdaten einen Payment Dienstleister, der die Abwicklung der Zahlung für Bestellungen übernimmt. Wir selbst verarbeitet keine Zahlungsinformationen, wie Kontoinformationen oder Kreditkartendaten auf eigenen Systemen. Die Zahlungsabwicklung und die dazugehörige Erfassung, Verarbeitung und Speicherung von Daten zur Abwicklung des elektronischen Zahlungsverkehrs erfolgt durch unseren Partner Adyen (N.V. German Branch). Adyen erhält im Rahmen der Abwicklung des elektronischen Zahlungsverkehrs die folgenden Kundendaten im Zusammenhang mit einer Bestellung: Vorname, Nachname, Adresse, PLZ, Stadt, Land, E-Mail, Art der Zahlung. Bei Zahlung per Kreditkarte werden zusätzlich die folgenden Informationen beim Kaufprozess direkt an Adyen übertragen: Kreditkartennummer, Kreditkarten-Besitzer, Kreditkarten-Gültigkeit (Monat und Jahr), Kreditkarten-CVC.<br />
                  <br />
                  Wir können die von Ihnen angegebenen Daten zudem verarbeiten, um Sie über weitere interessante Produkte aus unserem Portfolio zu informieren oder Ihnen E-Mails mit technischen Informationen zukommen lassen. Rechtsgrundlage hierfür ist Art. 6 Abs. 1 S. 1 lit. f DS-GVO.<br />
                  <br />
                  Wir sind aufgrund handels- und steuerrechtlicher Vorgaben verpflichtet, Ihre Adress-, Zahlungs- und Bestelldaten für die Dauer von zehn Jahren zu speichern. Allerdings nehmen wir nach drei Jahren eine Einschränkung der Verarbeitung vor, d. h. Ihre Daten werden nur zur Einhaltung der gesetzlichen Verpflichtungen eingesetzt.<br />
                  <br />
                  Zur Verhinderung unberechtigter Zugriffe Dritter auf Ihre persönlichen Daten, insbesondere ihre Finanzdaten, wird der Bestellvorgang per SSL oder TLS-Technik verschlüsselt.<br />
                  3.9.    Nutzung von Apps<br />
                  Wenn Sie die App nutzen, verzeichnen unsere Server temporär die IP-Adresse Ihres Gerätes und andere technische Merkmale, wie zum Beispiel die angefragten Inhalte (Art. 6 Abs. 1 lit. b DSGVO). Darüber hinaus erfolgt keine Nutzung der Daten durch Häfele. In dieser App haben Sie die Möglichkeit verschiedene Funktionalitäten zu nutzen, die durch einen Dritten (z.B. Apple oder Google) zur Verfügung gestellt und als Verantwortliche für die Datenverarbeitung genutzt werden. Zu Details zu der Funktionalität, und wie Sie die Nutzung ein- bzw. ausschalten können, informieren Sie sich bitte bei dem jeweiligen Betriebssystemhersteller.<br />
                  3.10.  Einbindung der Dienste Dritter <br />
                  Wir haben YouTube-Videos in unser Online-Angebot eingebunden, die auf https://www.youtube.com/ gespeichert sind und von unserer Webseite aus direkt abspielbar sind. Diese sind alle im „erweiterten Datenschutz-Modus“ eingebunden, d.h. dass keine Daten über Sie als Nutzer an YouTube übertragen werden, wenn Sie die Videos nicht abspielen. Erst wenn Sie die Videos abspielen, werden die in Ziff. 3.3 genannten Daten übertragen.Auf diese Datenübertragung haben wir keinen Einfluss.<br />
                  Wir haben auf dieser Webseite Google Maps eingebunden, einen Dienst der Google LLC. („Google“), Amphitheatre Parkway, Mountain View, CA 94043, USA, als Drittanbieter. Durch den Besuch auf der Webseite erhält der Drittanbieter die Information, dass Sie die entsprechende Unterseite unserer Webseite aufgerufen haben. Zudem werden die unter Ziff 3.3 dieser Erklärung genannten Daten übermittelt. Dies erfolgt unabhängig davon, ob dieser Drittanbieter ein Nutzerkonto bereitstellt, über das Sie eingeloggt sind, oder ob kein Nutzerkonto besteht. Wenn Sie bei dem Plug-in-Anbieter eingeloggt sind, werden diese Daten direkt Ihrem Konto zugeordnet. Wenn Sie die Zuordnung mit Ihrem Profil bei dem Plug-in-Anbieter nicht wünschen, müssen Sie sich vor Aktivierung des Buttons ausloggen.<br />
                  Google speichert diese Daten ggf. für Nutzungsprofile und nutzt diese für Zwecke der Werbung, Marktforschung und/oder bedarfsgerechten Gestaltung seiner Webseite. Eine solche Auswertung erfolgt insbesondere (auch für nicht eingeloggte Nutzer) zur Erbringung von bedarfsgerechter Werbung und um andere Nutzer des sozialen Netzwerks über Ihre Aktivitäten auf unserer Webseite zu informieren. Ihnen steht ein Widerspruchsrecht zu gegen die Bildung dieser Nutzerprofile, wobei Sie sich zur Ausübung dessen an Google richten müssen. Rechtsgrundlage für die Verarbeitung ist Art. 6 Abs. 1 lit. f DS-GVO.<br />
                  Weitere Informationen zu Zweck und Umfang der Datenerhebung und ihrer Verarbeitung durch den Plug-in-Anbieter erhalten Sie in der Datenschutzerklärung von Google: https://policies.google.com/privacy?hl=de&gl=de. Dort erhalten Sie auch weitere Informationen zu Ihren diesbezüglichen Rechten und Einstellungsmöglichkeiten zum Schutze Ihrer Privatsphäre. <br />
                  3.11.  Cookies<br />
                  Bei Ihrer Nutzung unserer Website werden Cookies auf Ihrem Rechner gespeichert. Bei Cookies handelt es sich um kleine Textdateien, die auf Ihrer Festplatte dem von Ihnen verwendeten Browser zugeordnet gespeichert werden und durch welche der Stelle, die den Cookie setzt (hier durch uns), bestimmte Informationen zufließen. Cookies können keine Programme ausführen oder Viren auf Ihren Computer übertragen. Sie dienen dazu, das Internetangebot insgesamt nutzerfreundlicher und effektiver zu machen. Rechtsgrundlage ist Art. 6 Abs. 1 lit. f DS-GVO.<br />
                  Einsatz von Cookies:<br />
                  1.	Diese Website nutzt folgende Arten von Cookies, deren Umfang und Funktionsweise im Folgenden erläutert werden:<br />
                  – Transiente Cookies (dazu 2.)<br />
                  – Persistente Cookies (dazu 3.).<br />
                  <br />
                  2.	Transiente Cookies werden automatisiert gelöscht, wenn Sie den Browser schließen. Dazu zählen insbesondere die Session-Cookies. Diese speichern eine sogenannte Session-ID, mit welcher sich verschiedene Anfragen Ihres Browsers der gemeinsamen Sitzung zuordnen lassen. Dadurch kann Ihr Rechner wiedererkannt werden, wenn Sie auf unsere Website zurückkehren. Die Session-Cookies werden gelöscht, wenn Sie sich ausloggen oder den Browser schließen.<br />
                  <br />
                  3.	Persistente Cookies werden automatisiert nach einer vorgegebenen Dauer gelöscht, die sich je nach Cookie unterscheiden kann. Sie können die Cookies in den Sicherheitseinstellungen Ihres Browsers jederzeit löschen.<br />
                  <br />
                  4.	Sie können Ihre Browser-Einstellung entsprechend Ihren Wünschen konfigurieren und z. B. die Annahme von Third-Party-Cookies oder allen Cookies ablehnen. Wir weisen Sie darauf hin, dass Sie eventuell nicht alle Funktionen dieser Website nutzen können.<br />
                  <br />
                  5.	Wir setzen Cookies ein, um Sie für Folgebesuche identifizieren zu können, falls Sie über einen Account bei uns verfügen. Andernfalls müssten Sie sich für jeden Besuch erneut einloggen.<br />
                  <br />
                  6.	Die genutzten Flash-Cookies werden nicht durch Ihren Browser erfasst, sondern durch Ihr Flash-Plug-in. Weiterhin nutzen wir HTML5 storage objects, die auf Ihrem Endgerät abgelegt werden. Diese Objekte speichern die erforderlichen Daten unabhängig von Ihrem verwendeten Browser und haben kein automatisches Ablaufdatum. Wenn Sie keine Verarbeitung der Flash-Cookies wünschen, müssen Sie ein entsprechendes Add-On installieren, z. B. „Better Privacy“ für Mozilla Firefox oder das Adobe-Flash-Killer-Cookie für Google Chrome. Die Nutzung von HTML5 storage objects können Sie verhindern, indem Sie in Ihrem Browser den privaten Modus einsetzen. Zudem empfehlen wir, regelmäßig Ihre Cookies und den Browser-Verlauf manuell zu löschen.<br />
                  Wenn Sie den Einsatz von Cookies verhindern möchten, können Sie die Annahme von Cookies in Ihrem Browser verweigern. Wie das im Einzelnen funktioniert, entnehmen Sie bitte der Anleitung Ihres Browser-Herstellers.<br />
                  3.12.  Google Analytics<br />
                  Diese Website benutzt Google Analytics, einen Webanalysedienst der GoogleLLC. („Google“), Amphitheatre Parkway, Mountain View, CA 94043, USA. Google Analytics verwendet sog. „Cookies“, Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Auf dieser Webseite ist Google Analytics um den Code „gat._anonymizeIp();“erweitert, um eine anonymisierte Erfassung von IP-Adressen (sog. IP-Masking) zu gewährleisten. Im Falle der Aktivierung der IP-Anonymisierung auf dieser Webseite, wird Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. In unserem Auftrag wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. Rechtsgrundlage für die Verarbeitung Ihrer Daten ist Art. 6 Abs. 1 lit. f DS-GVO. <br />
                  Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter dem folgenden Link (https://tools.google.com/dlpage/gaoptout?hl=de) verfügbare Browser-Plugin herunterladen und installieren.<br />
                  Es wird ein Opt-Out-Cookie gesetzt, der die zukünftige Erfassung Ihrer Daten beim Besuch dieser Website verhindert: Google Analytics deaktivieren. Weitergehende Informationen zu Nutzungsbedingungen und Datenschutz finden Sie unter http://www.google.com/analytics/terms/de.html bzw. unter https://www.google.de/intl/de/policies/. <br />
                  3.13.  Social Media Plugins<br />
                  Verwendung von Facebook und Twitter Plugins auf der Social Media Seite. <br />
                  Wenn Sie die Social Media Seite aufrufen, werden sogenannte Social Plugins („Plugins“) des sozialen Netzwerks Facebook und des Mikroblogging-Dienstes Twitter verwendet. Diese Dienste werden von den Unternehmen Facebook Inc. und Twitter Inc. angeboten („Anbieter“). Facebook wird betrieben von der Facebook Inc.,1601 S. California Ave, Palo Alto, CA 94304, USA ("Facebook"). Twitter wird betrieben von der Twitter Inc., 1355 Market St, Suite 900, San Francisco, CA 94103, USA („Twitter“). Eine Übersicht über die Plugins und deren Aussehen finden Sie hier: https://developers.facebook.com/docs/plugins bzw. https://dev.twitter.com/web/overview. <br />
                  Wenn Sie die Seite Social Media unseres Webauftritts aufrufen, stellt Ihr Browser eine direkte Verbindung zu den Servern von Facebook oder Twitter her. Der Inhalt des Plugins wird vom jeweiligen Anbieter direkt an Ihren Browser übermittelt und in die Seite eingebunden. Durch die Einbindung der Plugins erhalten die Anbieter die Information, dass Ihr Browser die entsprechende Seite unseres Webauftritts aufgerufen hat, auch wenn Sie kein Profil bei dem entsprechenden sozialen Netzwerk besitzen oder gerade nicht eingeloggt sind. Diese Information (einschließlich Ihrer IP-Adresse) wird von Ihrem Browser direkt an einen Server des jeweiligen Anbieters in die USA übermittelt und dort gespeichert.<br />
                  Sind Sie bei Facebook oder Twitter eingeloggt, können die Anbieter den Besuch unserer Website Ihrem Profil auf Facebook bzw. Twitter unmittelbar zuordnen. Wenn Sie mit den Plugins interagieren, zum Beispiel den „Gefällt mir“-Button oder den „+1“-Button betätigen, wird die entsprechende Information ebenfalls direkt an einen Server der Anbieter übermittelt und dort gespeichert. Die Informationen werden außerdem in dem sozialen Netzwerk veröffentlicht und dort Ihren Kontakten angezeigt. Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch die Anbieter sowie Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre entnehmen Sie bitte den Datenschutzhinweisen der Anbieter.<br />
                  Datenschutzhinweise von Facebook: <br />
                  http://www.facebook.com/policy.php <br />
                  Datenschutzhinweise von Instagram:<br />
                  https://help.instagram.com/155833707900388<br />
                  Datenschutzhinweise von Twitter: <br />
                  https://twitter.com/privacy <br />
                  Wenn Sie nicht möchten, dass Facebook, Twitter oder Instagram die über unseren Webauftritt gesammelten Daten unmittelbar Ihrem Profil in dem jeweiligen sozialen Netzwerk zuordnen, müssen Sie sich vor Ihrem Besuch unserer Website bei dem entsprechenden Netzwerk ausloggen. Sie können das Laden der Plugins auch mit Add-Ons für Ihren Browser komplett verhindern, z. B. mit dem Skript-Blocker „NoScript“ (http://noscript.net/).<br />
                  Wir betreiben die Onlinepräsenzen innerhalb der sozialen Netzwerke Facebook, Instagram und Twitter, um mit unseren Geschäftspartnern zu kommunizieren und um mit Ihnen als Besucher dieser Seite in Kontakt zu treten. Der Betrieb dieser Seiten unter Einbezug der Verarbeitung der personenbezogenen Daten der Nutzer erfolgt auf Grundlage unserer berechtigten Interessen an einer unterstützenden Informations- und Interaktionsmöglichkeit mit unseren Kunden gemäß Art. 6 Abs. 1 lit. f. DSGVO. Falls die Nutzer von den jeweiligen Anbietern der Plattformen um eine Einwilligung in die vorbeschriebene Datenverarbeitung gebeten werden, ist die Rechtsgrundlage der Verarbeitung Art. 6 Abs. 1 lit. a., Art. 7 DSGVO.<br />
                  Wir machen darauf aufmerksam, dass die Übermittlung und weitere Verarbeitung personenbezogener Daten der Benutzer in Drittländer, wie z.B. die USA, sowie die damit verbundenen eventuellen Risiken für die Benutzer (z.B. in Form einer erschwerten Rechtsdurchsetzung) von uns als Betreiber der Seite nicht ausgeschlossen werden können. US-Anbieter die unter dem Privacy-Shield zertifiziert sind verpflichten sich, die Datenschutzstandards der EU einzuhalten.<br />
                  Die Daten der Nutzer werden im Regelfall für Marktforschungs- und Werbezwecke verarbeitet. So können z.B. aus dem Nutzungsverhalten und sich daraus ergebenden Interessen der Nutzer Nutzungsprofile erstellt werden. Die Nutzungsprofile können wiederum verwendet werden, um z.B. Werbeanzeigen innerhalb und außerhalb der Plattformen zu schalten. Es werden zu diesen Zwecken regelmäßig Cookies auf den Rechnern der Nutzer gespeichert, in denen das Nutzungsverhalten und die Interessen der Nutzer gespeichert werden. Darüber hinaus können in den Nutzungsprofilen auch Daten geräteübergreifend gespeichert werden.<br />
                  Facebook<br />
                  Der Europäische Gerichtshof (EuGH) hat in seinem Urteil vom 5. Juni 2018 entschieden, dass der Betreiber einer Facebook-Fanpage gemeinsam mit Facebook für die Verarbeitung personenbezogener Daten verantwortlich ist. Die Datenschutzerklärung von Facebook enthält weitere Informationen zur Datenverarbeitung: https://www.facebook.com/about/privacy/. Eine Widerspruchsmöglichkeit (sog. Opt-Out) kann hier: https://www.facebook.com/settings?tab=ads wahrgenommen werden. <br />
                  Über die sogenannten „Insights“ der Facebook-Seite sind statistische Daten unterschiedlicher Kategorien für uns abrufbar. Diese Statistiken werden durch Facebook erzeugt und bereitgestellt. Auf die Erzeugung und Darstellung haben wir als Betreiber der Seite keinen Einfluss. Wir können diese Funktion nicht abstellen oder die Erzeugung und Verarbeitung der Daten nicht verhindern. Informationen zu dieser Datenverarbeitung finden Sie bei direkt Facebook unter https://www.facebook.com/legal/terms/information_about_page_insights_data. <br />
                  Instagram<br />
                  Die Datenschutzhinweise von Instagram finden Sie unter: https://help.instagram.com/155833707900388.<br />
                  Twitter<br />
                  Die Datenschutzhinweise von Twitter finden Sie unter: https://twitter.com/de/privacy. Eine Möglichkeit zum Opt-Out finden Sie unter: https://twitter.com/personalization.<br />
                  <br />
                  <h4 class='data__subheading'>4.    Datenübermittlungen an Dritte</h4>
                  Wir übertragen Ihre Daten an Auftragsverarbeiter, das sind Unternehmen, die wir im gesetzlich vorgesehenen Rahmen mit der Verarbeitung von Daten beauftragen, Art. 28 DS-GVO (Dienstleister, Erfüllungsgehilfen). Häfele bleibt auch in dem Fall weiterhin für den Schutz Ihrer Daten verantwortlich. Wir haben durch rechtliche, technische und organisatorische Maßnahmen sowie durch regelmäßige Kontrollen sichergestellt, dass Auftragsverarbeiter die Vorschriften der Datenschutzgesetze einhalten. Wir beauftragen Unternehmen insbesondere in folgenden Bereichen: IT, Vertrieb, Marketing, Finanzen, Beratung, Kundenservice, Personalwesen, Logistik, Druck.<br />
                  Wir übermitteln Ihre Daten auch an unsere Kooperationspartner, die in eigener Verantwortung Leistungen für Sie erbringen (Lieferanten, Zustelldienste). Dies ist der Fall, wenn Sie Leistungen solcher Partner bei uns beauftragen oder wenn Sie in die Einbindung des Partners einwilligen oder wenn wir den Partner aufgrund einer gesetzlichen Erlaubnis, etwa zur Vertragserfüllung nach Art. 6 Abs. 1 lit. b<br />
                  Die Übermittlung von personenbezogenen Daten innerhalb der Unternehmen der Häfele Group erfolgt zu internen Verwaltungszwecken der zentralen Kundenbetreuung und Auftragsbearbeitung. Rechtsgrundlage hierfür ist Art. 6 Abs. 1 lit. f DS-GVO. Häfele verpflichtet seine Unternehmen durch interne Richtlinien zur Umsetzung von technisch organisatorischen Maßnahmen, um die Sicherheit der Verarbeitung zu gewährleisten. <br />
                  Schließlich sind wir in bestimmten Fällen gesetzlich verpflichtet, bestimmte Daten an die anfragende staatliche Stelle zu übermitteln.<br />
                   <br />
                  <h4 class='data__subheading'>5.    Dauer der Speicherung </h4>
                  Personenbezogene Daten werden – soweit in dieser Datenschutzerklärung nicht anderweitig beschrieben –  gelöscht, sobald sie ihren jeweils beschriebenen Zweck erfüllt haben und der Löschung keine Aufbewahrungspflichten entgegenstehen. Nach Ablauf der Aufbewahrungsfrist erfolgt eine routinemäßige Löschung der Daten, sofern nicht eine Erforderlichkeit für eine Vertragsanbahnung,  die Vertragserfüllung oder einen sonstige Rechtsgrundlage für die Datenverarbeitung besteht. <br />
                   <br />
                  <h4 class='data__subheading'>6.    Sicherheit der Verarbeitung  </h4>
                  Wir unterhalten aktuelle technische und organisatorische Maßnahmen zur Gewährleistung der Sicherheit der Verarbeitung, insbesondere zum Schutz Ihrer personenbezogenen Daten vor Gefahren bei Datenübertragungen sowie vor Kenntniserlangung durch unbefugte Dritte. Diese werden dem aktuellen Stand der Technik, dem Schutzbedarf der personenbezogenen Daten und den Risiken für Ihre Rechte und Freiheiten angepasst. Ihre Daten werden grundsätzlich in Deutschland und im europäischen Ausland verarbeitet. Findet eine Verarbeitung Ihrer Daten in Ausnahmefällen auch in Ländern außerhalb der Europäischen Union (also in sog. Drittstaaten) statt, geschieht dies, soweit Sie hierin ausdrücklich eingewilligt haben oder es für unsere Leistungserbringung Ihnen gegenüber erforderlich ist oder es gesetzlich vorgesehen ist (Art. 49 DS-GVO). Darüber hinaus erfolgt eine Verarbeitung Ihrer Daten in Drittstaaten nur, soweit durch bestimmte Maßnahmen sichergestellt ist, dass hierfür ein angemessenes Datenschutzniveau besteht (z.B. Angemessenheitsbeschluss der EU-Kommission oder sog. geeignete Garantien, Art. 44ff. DS-GVO).<br />
                   <br />
                  <h4 class='data__subheading'>7.    Betroffenenrechte</h4>
                  Sie haben das Recht,<br />
                  1.	Auskunft zu verlangen zu Kategorien der verarbeiteten Daten, Verarbeitungszwecken, etwaigen Empfängern der Daten, der geplanten Speicherdauer (Art. 15 DS-GVO);<br />
                  2.	die Berichtigung bzw. Ergänzung unrichtiger bzw. unvollständiger Daten zu verlangen (Art. 16 DS-GVO);<br />
                  3.	eine erteilte Einwilligung jederzeit mit Wirkung für die Zukunft zu widerrufen (Art. 7 Abs. 3 DS-GVO);<br />
                  4.	einer Datenverarbeitung, die aufgrund eines berechtigten Interesses erfolgen soll, aus Gründen zu widersprechen, die sich aus Ihrer besonderen Situation ergeben (Art 21 Abs. 1 DS-GVO);<br />
                  5.	in bestimmten Fällen im Rahmen des Art. 17 DS-GVO die Löschung von Daten zu verlangen - insbesondere soweit die Daten für den vorgesehenen Zweck nicht mehr erforderlich sind bzw. unrechtmäßig verarbeitet werden, oder Sie Ihre Einwilligung gemäß oben (3) widerrufen oder einen Widerspruch gemäß oben (4) erklärt haben;<br />
                  6.	unter bestimmten Voraussetzungen die Einschränkung von Daten zu verlangen, soweit eine Löschung nicht möglich bzw. die Löschpflicht streitig ist (Art. 18 DS-GVO);<br />
                  7.	auf Datenübertragbarkeit, d.h. Sie können Ihre Daten, die Sie uns bereitgestellt haben, in einem gängigen maschinenlesbaren Format wie z.B. CSV erhalten und ggf. an andere übermitteln (Art. 20 DS-GVO);<br />
                  8.	sich bei der zuständigen Aufsichtsbehörde über die Datenverarbeitung zu beschweren, mithin insbesondere beim Landesbeauftragten für den Datenschutz Baden-Württemberg (https://www.baden-wuerttemberg.datenschutz.de/).<br />
                 <br />
                  <h4 class='data__subheading'>8.    Änderung der Datenschutzerklärung</h4>
                  Wir behalten uns vor, diese Datenschutzerklärung zu ändern, um sie an geänderte Rechtslage oder bei Änderungen unserer Angebote anzupassen. Die älteren Versionen bleiben abrufbar.<br />
                  <br />
                  <h4 class='data__subheading'>9.    Datenschutzinformationen für Bewerber</h4>
                  Soweit Sie uns Ihre Bewerbungsunterlagen und persönliche Daten überlassen, willigen Sie in die Erhebung, Speicherung und Nutzung der Daten im Rahmen des Bewerbungsprozesses ein. Rechtsgrundlage der Datenverarbeitung ist Art. 6 Abs. 1 Buchst. b) DSGVO in Verbindung mit §26 Abs 1 BDSG.<br />
                  <br />
                  Rechte der Betroffenen<br />
                  Sie haben nach Maßgabe der DSGVO und des BDSG Anspruch auf Auskunft, Berichtigung, Löschung, Einschränkung der Bearbeitung und Datenübertragung. Wollen Sie Ihre Rechte geltend machen können Sie sich direkt an unseren Datenschutzbeauftragten wenden.<br />
                  Ihre Daten werden spätestens nach 12 Monaten gelöscht oder bei Einstellung in die Personalverwaltung von Häfele überführt. Rechtsgrundlage hierfür ist Art. 6 Abs. 1 Buchst. b) DSGVO. Der Verbleib Ihrer Bewerbung im Bewerber-Pool oder die Weitergabe Ihrer Bewerbungsunterlagen innerhalb der Unternehmen der Häfele Gruppe bedarf Ihrer gesonderten Einwilligung.<br />
                  <br />
                  Nagold, den 22. Mai 2018
                  <br />
                  <br />
              </div>
          </div>
        </div>
      </div>

      <div class="col-md-1"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Register',
  data() {
    return {
      user: {
        first_name: '',
        last_name: '',
        company: '',
        email: '',
        password: '',
        // Added those hard coded as there are not yet implemented in our front end
        company_position: '',
        inviter_id: '',
        accept_terms: false,
        recaptcha_token: ''
      },
      status: '',
      siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
      firmaName: '',
      submitted: false,
      type: 'password',
      eyeClass: 'fa fa-fw fa-eye field-icon toggle-password'
    };
  },
  methods: {
    togglePassword() {
      if (this.eyeClass.includes('fa-eye-slash')) {
        this.eyeClass = this.eyeClass.replace('fa-eye-slash', 'fa-eye');
        document.getElementById('input-pwd').type = 'password';
      } else {
        this.eyeClass = this.eyeClass.replace('fa-eye', 'fa-eye-slash');
        document.getElementById('input-pwd').type = 'text';
      }
    },    
    goHome() {
      this.$router.push('/');
    },
    validateForm() {
      this.submitted = true;
      this.$validator.validate().then(valid => {
        if (valid) {
          this.$refs.recaptcha.execute();
        }
      });
    },
    async register() {
      const form = new FormData();
      form.append('token', this.$route.query.t);
      try {
        let response = await this.$http.post('/verify-email', form);
        if (response.status == 200) {
          console.log('success');
        }
      } catch (e) {
        console.log(e);
      }
    },
    onCaptchaVerified(recaptchaToken) {
      this.status = 'submitting';
      this.$refs.recaptcha.reset();
      this.user.recaptcha_token = recaptchaToken;
      this.register();
      this.status = '';
    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
    },
    goToLogin() {
      this.$router.push('/login');
    }
  },
  created() {
    this.firmaName = this.$route.query.inviter || 'Hafele';
    this.user.inviter_id = this.$route.query.inviter_id || '4';
  },
  async mounted() {

    document.getElementById('backbtn').onclick = function() { window.close(); };
    document.getElementById('customerlogo').onclick = function() { window.close(); };
    document.getElementById('searchfield').remove();

    let recaptchaScript = document.createElement('script');
    recaptchaScript.setAttribute(
      'src',
      'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit'
    );
    recaptchaScript.setAttribute('async', '');
    recaptchaScript.setAttribute('defer', '');
    document.head.appendChild(recaptchaScript);
    await this.register();
  }
};
</script>

<style lang="scss" scoped>
header {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  z-index: -1;
}

header p {
  text-transform: none;
  margin: 0;
  font-size: 14rem;
  opacity: 0.5;
  text-align: left;
  font-weight: 350;
  line-height: 0.5;
  letter-spacing: -5.7px;
  color: #ffffff;
}

.footer {
  margin-top: 10px !important;
  margin-bottom: 0p !important;
  font-size: 10px;
  color: white;
  font-weight: 300;
}

.back {
    width: 10%;
    color: white;
    font-size: 12px;
}

.back:hover {
    cursor: pointer;
}
.data{
  &__subheading{
    font-weight: 700;
  }
}

.error-mssg {
  font-size: 12px;
}

.toggle-password:hover {
  cursor: pointer;
}

.card {
  border-radius: 0;
}

.card-body {
  padding: 2rem 3.5rem;
  height: 75vh;
  overflow-y: scroll;

  div{
    margin-top: 3rem;
  }
}

.container {
  margin-top: $loginMarginTop;
  color: white;
}

.register {
  background-color: rgba(80, 80, 80, 0.9);
  margin-bottom: 40px !important;
}

.card-title {
  font-weight: 300 !important;
  color: $secondaryColor;
  font-size: 42px;
  padding-bottom: 10px !important;
}

.card-text {
  font-size: 16px !important;
  font-weight: 400;
}

input {
  font-size: 16px !important;
}

.register .text-field {
  border-radius: 0%;
  background-color: rgba(80, 80, 80, 0.9);
  color: white;
  border-color: rgba(255, 255, 255, 0.7);
}

.register ::placeholder {
  color: white;
  opacity: 0.5;
}

.field-icon {
  float: right;
  margin-right: 10px;
  margin-top: -35px;
  position: relative;
  z-index: 2;
  color: white;
  opacity: 0.8;
}

.checkbox-text {
  font-size: 16px;
}

.checkbox-text span {
  font-weight: 700;
}

.register .btn {
  color: black;
  background-color: white;
  height: 37px;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.15px;
  padding-left: 7%;
  padding-top: 1.5%;
  text-transform: capitalize;
  margin-top: 1.5rem !important;
  margin-bottom: 1rem;
  border-radius: 0 !important;
}

.register .btn:hover {
  background-color: $secondaryColor;
}

@media screen and (max-device-width: 640px) and (orientation: landscape) {
  header {
    visibility: hidden;
    clear: both;
    float: left;
    margin: 10px auto 5px 20px;
    width: 28%;
    display: none;
  }
}

@media screen and (max-device-width: 480px) and (orientation: portrait) {
  header {
    visibility: hidden;
    clear: both;
    float: left;
    margin: 10px auto 5px 20px;
    width: 28%;
    display: none;
  }

  .card-text {
    font-size: 10px !important;
  }

  input {
    font-size: 10px !important;
  }

  .register .btn {
    font-size: 10px !important;
    padding-left: 15px !important;
    padding-top: 10px !important;
  }

  .checkbox-text {
    font-size: 10px !important;
  }

  p {
    font-size: 14px !important;
  }
}

@media screen and (max-device-width: 1024px) {
  header {
    visibility: hidden;
    clear: both;
    float: left;
    margin: 10px auto 5px 20px;
    width: 28%;
    display: none;
  }
}
</style>
